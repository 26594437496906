/* NOTE: Start of Whole Doc */
html,
body {
  /* width: 100%; */
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

h1,
h2,
h3,
p,
footer li {
  text-align: center;
}

p {
  font-size: 1.5rem;
}

h1 {
  /* font-size: 3rem; */
  margin-bottom: 2rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
}

section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

section.rounded-section {
  /* For form-section and bio-section */
  border-radius: 1rem;
}

.body-margin {
  margin: 0 12vw;
}

.hero-section {
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh; /*To offset from the navbar */
  height: 70vh;
  /* background-color: red; */
}

.hero-section p {
  font-size: 1.6rem;
  color: white;
}

.hero-section h3 {
  font-size: 3.5rem;
  font-weight: 750;
  letter-spacing: 0.05em;
  padding: 2rem;
  color: white;
  text-align: center;
}

.yellowBackground {
  /* background-color: #FCCA46; */
  background-color: #f3a712;
}

/* End of Whole Doc */

/* NOTE: Start of Navbar */

#logo {
  max-width: 6rem;
  margin-top: -3rem;
  margin-left: -1.5rem;
  position: absolute;
}

#NavB {
  /* Entire Navbar */
  position: absolute;
  top: 1rem;
  left: 5vw; /* same as body-margin */
  right: 5vw; /* same as body-margin */
  z-index: 2;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

#navLinks {
  margin-left: 3rem;
}

.nav-link:hover {
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  font-weight: 600;
  text-decoration: none;
}

#nav-button-contact {
  background-color: #8000ff;
  border-color: #8000ff;
  margin-left: 5px;
}

#nav-button-help {
  background-color: #f3a712;
  border-color: #f3a712;
}

/* End of Navbar */

#footer-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

/* NOTE:Start of Homepage */

#carousel-hero-1 {
  /* Slide1: GIVE YOUR LIFE DONT TAKE IT */
  background-image: url("./assets/getInvolvedImage.jpg");
}

#carousel-hero-2 {
  /* Slide2: Ghandi Quote */
  background-image: url("./assets/secondImageFinal.jpg");
}

#carousel-hero-3 {
  /* Slide3: Asking the question */
  background-image: url("./assets/feetinsand.jpg");
}

#carousel-hero-4 {
  /* Slide4: Kept Trying */
  background-image: url("./assets/lastImage.jpg");
}

#thirdoption-section {
  background-color: #1b1b3a;
  color: white;
  width: 100%;
}
/* #contact-form {
  background-color: #552c88;
  padding:1rem
} */

#form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #343a40;
  color: white;
  margin-top: 2.5rem;
}

#form-section form {
  padding: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

#form-section p {
  text-align: center;
}

/* NOTE: Start of About Us Page*/
#mission-hero {
  background-image: url("./assets/about-Helping-Others-drawing.jpg");
}

#whywedoit-main {
  display: flex;
  align-items: center;
}

#whywedoit-video {
  /* contains the iframe video of pam */
  height: 600px;
  width: 100%;
  min-width: 400px;
}

#whywedoit-video iframe {
  height: 100%; /* takes up entire height of whywedoit-video container */
  width: 100%; /* takes up entire width of whywedoit-video container */
}

#founder-bio-section {
  color: white;
  border-radius: 1rem;
  background-color: #1b1b3a;
  padding: 2rem 2rem;
}

#founder-bio-text p {
  text-align: left;
}

#founder-pic {
  float: left;
  min-width: 200px;
  height: auto;
  margin: 1rem;
}

#founder-bio-text p {
  text-align:justify;
}


#pamSocial, #last-line {
  display: flex;
  justify-content: flex-end;
}

/* end of about us page */

/* NOTE: start of get involved */

#get-involved-hero {
  background-image: url("./assets/getInvolvedImage.jpg");
}

#get-involved-hero p {
  font-weight: 700;
}

footer ul {
  padding: 0; /* resets default left padding from ul's for the citations */
}

footer li {
  font-size: 1.25em;
  list-style-type: none;
}

footer li a {
  color: darkgray;
}

#ways-to-section {
  background-color: #343a40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 0;
  color: white;
}

#opportunity-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.opportunity {
  width: 30%;
  text-align: center;
  /* border: white 1px solid; */
}

.opportunity-image {
  width: 100%;
  height: auto;
  margin-bottom: 0.75rem;
}

.card-text {
  font-size: 1rem;
}

/* End of Get Involved Page */

/* Start of Resources Page */
/* div .resourcesMain {
  margin-top: 200px;
} */

/* End of Get Involved Page */

/* NOTE: Start of Resources Page */

.resourcesTitle {
  text-align: center;
  color: #1b1b3a;
}

.resourcesMain {
  justify-content: center;
  margin: 9rem 2rem 0 2rem;
  padding: 0 0 0 0;
}

.resourcesCard {
  width: 30%;
  min-width: 400px;
  margin: 0.5rem;
}

.black-text a {
  color: black;
  font-size: 1.15rem;
}

.black-text:hover a {
  font-weight: 600;
}

.cardImg {
  width: 100%;
  height: 16vh;
  min-height: 375px;
  object-fit: cover;
  border-radius: 20px;
}

#phoneNumber {
  font-size: 1.15rem;
  text-align: center;
  width: 350px;
  padding: 0.25rem;
  border-radius: 5px;
  background-color: #1b1b3a;
  color: white;
  margin-bottom: 1rem;
}

#phoneNumber:hover {
  font-weight: 600;
}

.hovereffect:hover {
  filter: brightness(0.7);
}

/* End of Resources */

/* NOTE: Media Queries */

@media screen and (max-width: 440px) {
  p {
    font-size: 1.2rem;
  }

  .hero-section p {
    font-size: 1.35rem;
  }
}

@media screen and (max-width: 670px) {
  #founder-bio-section {
    display: flex;
    flex-direction: column;
    border-radius: 0%;
    align-items: center;
  }

  #founder-bio-text p {
    text-align: center;
  }

  #founder-pic {
    max-width: 400px;
  }

  .body-margin {
    margin: 0;
  }

  #footer-section {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  #contact-form {
    width: 100%;
  }

  #opportunity-container {
    flex-direction: column;
    justify-content: center;
  }

  .opportunity {
    width: 100%;
    margin-bottom: 1rem;
  }

  .card-text {
    font-size: 1.5rem;
  }

  #founder-bio-section {
    padding: 1rem;
  }

  #founder-pic {
    min-width: 250px;
    height: auto;
    margin: 1rem;
  }
}

@media screen and (max-width: 992px) {
  #whywedoit-main {
    flex-direction: column;
  }

  #whywedoit-video {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  #founder-bio-main {
    flex-direction: column;
  }
  #navLinks {
    margin: 0;
    padding-top: 10px;
  }
}
